<template>
  <!-- eslint-disable -->
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 px-1 py-1 px-lg-6 py-lg-3" no-body>
        <b-card-title class="mb-1 font-weight-bold text-center d-flex flex-column align-items-center" title-tag="h2">
          <img class="img_login" src="@/assets/images/logo/logo_white.png" alt="">
          Sistema de Alerta Temprana
        </b-card-title>
        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-1" @submit.prevent="login">
            <!-- Documento -->
            <b-form-group label-for="login-usernam,e" label="Usuario">
              <validation-provider #default="{ errors }" name="username" rules="requeridoE">
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-usernam,e"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Usuario"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
              </div>
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Contraseña"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <vue-recaptcha
                class="mt-2 md-2 vueCaptchaLogin"
                sitekey="6LeLSIYpAAAAAEePa5cJA5VQfEB3K-QTgHM7gJZr"
                :load-recaptcha-script="true"
                @verify="handleVerify"
                @expired="handleExpired"

              ></vue-recaptcha>
            </b-form-group>
            
            <b-button variant="primary" type="submit" block :disabled="invalid || !captchaVerified">
              Iniciar Sesion
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip, VBPopover } from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import AuthService from '@/services/AuthService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import router from '@/router/index'
import { bubbleToast } from '../../router/bubble.js'; // Ajusta la ruta según la ubicación de index.js
import VueRecaptcha from "vue-recaptcha";
import axios from 'axios';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    vSelect,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      role: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/access/gqr-logo.webp'),
      captchaVerified: false,
      ipAddress: null,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  mounted() {
    this.getIpAddress()
  },
  methods: {
   
    handleVerify(){
      console.log('captcha verified')
      this.captchaVerified = true
    },
    handleExpired(){
      console.log('captcha expired')
      this.captchaVerified = false
    },
    async getIpAddress() {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        this.ipAddress = response.data.ip;
        console.log('IP address:', this.ipAddress);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    },
    login() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          const response = await AuthService.login(
            {
              username: this.username,
              password: this.password,
              via: 'web',
              terminal: this.ipAddress
            },
            this.$store
          )
          console.log('response',response)
          if (response.status) {
            var userData = response.data
            userData.ability = [{ action: 'manage', subject: 'all' }]
            userData.role = userData.role
            useJwt.setToken(userData.token)
            useJwt.setRefreshToken(userData.token)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)         
            const projectId = localStorage.getItem('project_id');
            const userId =localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).id:null

            if(userData.role_user.length){
              if(userData.role_user[0].is_restriction || userData.role_user[0].role_lesson=='admin-lesson'){
                if (userData.role_user[0].role_restriction !='admin' && userData.projects.length == 1) {
                  localStorage.setItem('project_id', userData.projects[0].id)
                  localStorage.setItem('project_name', userData.projects[0].description)
                }
                this.$router.push({ name: 'seleccionar-log-type'})
              }else if (userData.role_user[0].is_lesson){
                
                this.$router.push({ name: 'seleccionar-log-type'})
                
                // bubbleToast(projectId,userId);
                console.log("LLAMADA POR LOGIN LECCIONES")
                }
            }else {
              this.$swal({
                title: 'Error!',
                text: "Sin permiso alguno",
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
            // if(userData.role === 'admin-lesson' || userData.role === 'user-lesson'  || userData.role === 'reviewer-lesson'){
            //   this.$router.push({ name: 'lecciones'})
            // }else {

            //   this.$router.push({ name: 'seleccionar-proyecto'})
            // }
            
            // this.$router.push({ name: getHomeRouteForLoggedInUser(userData.role) }).then(() => {
            //   this.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: `Bienvenido ${userData.name}`,
            //       icon: 'CoffeeIcon',
            //       variant: 'success',
            //       text: `Listos para comenzar!`
            //     }
            //   })
            // })
          } else {
            this.$swal({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
.bg-yellow {
  background-color: #fcec3870;
}
.img_login{
  filter: invert(1);
  max-width: 100px;
  margin-bottom: 2rem;
}
div[style="z-index: 2000000000; position: relative; width: 400px; height: 580px;"]{
  width: 100% !important;
  height: 100% !important;
}
iframe[title="El reCAPTCHA caduca dentro de dos minutos"]{
  width: 500px !important;
  height: 700px !important;


}
.vueCaptchaLogin{
  display: flex;
  margin-bottom: 10px;
  margin-left: 10px;
 
  iframe:first-child{
    width: 365px;
    height: 100px;
  }
  /* iframe[title="El reCAPTCHA caduca dentro de dos minutos"]{
    width: 475px !important;
    height: 680px !important;
  } */
}
/* #app{
  iframe{
    width: 475px !important;
    height: 680px !important;
  }
} */
</style>
